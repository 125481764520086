import { FormGroup } from "@angular/forms";
import { Seat } from "../event/seat-map/seat-map.models";
import { Cart, CartItem, CartSummary, Event, TicketType } from "../models";
import { UtilConstants } from "./util.constants";

export abstract class CartUtil {
  static buildCart(form: FormGroup, event: Event, ticketTypes: TicketType[], seats?: Seat[]): Cart {
    const cart = new Cart();
    cart.items = [];
    ticketTypes.forEach(t => {
      const value = form.get(UtilConstants.TICKET_TYPE_CONTROL_NAME(t.id)).value;
      if (value > 0) {
        const ticketType = ticketTypes.find(x => x.id == t.id);
        const seatsByTicketType = ticketType.reserved_seats ? seats.filter(x => x.ticket_type_id = ticketType.id) : [];
        for (let index = 0; index < value; index++) {
          const item = new CartItem();
          item.itemType = 'event';
          item.ticketPrice = { id: ticketType.price_id };
          item.product = { id: event.product_id, type: 'event' };
          item.price = ticketType.price;
          if (ticketType.reserved_seats) {
            item.seat = {
              id: seatsByTicketType[index].id,
              key: seatsByTicketType[index].id,
              objectType: 'Seat'
            };
          }
          cart.items.push(item);
        }
      }
    });
    return cart;
  }

  static calculatedCartSummary(cart: Cart): CartSummary {
    const cartSummary = new CartSummary();
    cart.items.forEach(i => {
      cartSummary.subTotal = cartSummary.subTotal + i.price;
      cartSummary.fees = cartSummary.fees + i.fees;
      cartSummary.total = cartSummary.total + i.totalAmount;
    });
    cart.totalAmount = cartSummary.total;
    return cartSummary;
  }

  static serialize(cart: Cart): any {
    return {
      items: cart.items.map(i => {
        return {
          itemType: i.itemType,
          ticketPrice: { id: i.ticketPrice.id },
          product: { id: i.product.id, type: i.product.type },
          seat: i.seat,
          price: i.price
        };
      }),
      method: cart.method,
      holdToken: cart.holdToken ? cart.holdToken : null,
      totalAmount: cart.totalAmount,
      phoneNumber: cart.phoneNumber
    }
  }
}
