// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { env } from './.env';

export const environment = {
  production: false,
  version: env.npm_package_version + '-dev',
  firebase: {
    apiKey: "AIzaSyArkyTt7lgMU8HOFByNv-kW24zigbVrWJQ",
    authDomain: "ticket-spicket-next-gen.firebaseapp.com",
    databaseURL: "https://ticket-spicket-next-gen-default-rtdb.firebaseio.com",
    projectId: "ticket-spicket-next-gen",
    storageBucket: "ticket-spicket-next-gen.appspot.com",
    messagingSenderId: "468348266818",
    appId: "1:468348266818:web:e4ac10297d9c676a4980db",
    measurementId: "G-25WX8DLGW6"
  },
  seatsio: {
    apiUrl: 'https://api.seatsio.net',
    publicKey: '36e67f52-a30e-4d22-a257-195341419030',
    encodedKey: "MDFmYTQzNGUtODZkYi00MjJkLThjZmMtMWI5N2QzYjM5ZmQyOg=="
  },
  googlePayMerchantId: 'BCR2DN4TZCR47PS6',
  serverUrl: env.JAVA_MONOLITH_URL || 'https://dev-api.ticketspicket.com:8443/v2',
  apiUrl: env.FAN_WEB_API_URL || 'https://fan-web-api-dev.ticketspicket.com'
};


// https://dev-api.ticketspicket.com:8443/v2/fans/guest/tickets/69927b34-1ec3-42f5-9fa2-9989b63b3a3a
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
//import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
