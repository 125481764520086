import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import * as Sentry from "@sentry/angular";
import { Integrations } from "@sentry/tracing";

// app version
const version = '6.2.5';

Sentry.init({
  release: "fast-lane@" + version,
  environment: environment.production ? 'production' : 'dev',
  dsn: "https://7f41aaf7afda4db78cd7135e6206d025@o1116650.ingest.sentry.io/6150726",
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],
  tracesSampleRate: environment.production ? 0.15 : 1.0
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
