export class CartSummary {
  subTotal: number = 0;
  fees: number = 0;
  total: number = 0;
}

export class Cart {
  items: CartItem[];
  method: any;
  holdToken: string = null;
  totalAmount: number;
  promotionCode: string;
  promotion?: any;
  other?: any;
  phoneNumber: string;
}

export class CartItem {
  itemType: string;
  ticketPrice: {
    id: number
  };
  product: {
    id: number;
    type: string;
  };
  seat?: {
    id: string;
    key: string;
    objectType: string;
  };
  price: number;

  // should set based on response from calculate-cart api call
  totalAmount: number;
  fees: number;
}

