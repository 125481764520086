import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

export class SeatsioHoldToken {
  holdToken: string = null;
  expiresAt: string = null;
  expiresInSeconds: number = 0;

  isExpired(): boolean {
    if (this.holdToken !== null) {
      return moment().isAfter(moment.utc(this.expiresAt).local())
    } else {
      return true;
    }
  }
}

@Injectable({ providedIn: 'root' })
export class SeatsioTokenService {

  private apiUrl = environment.seatsio.apiUrl;
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Basic ${environment.seatsio.encodedKey}`
    })
  };
  private holdtoken_storage_key = 'com.ticketspicket.go.cart-seatsio-holdtoken';

  constructor(private http: HttpClient) {
  }

  getHoldToken(): Observable<{ token: SeatsioHoldToken, new: boolean }> {
    const session = this.getHoldTokenFromStorage();
    if (!session || (session && session.isExpired())) {
      return this.generateHoldToken().pipe(map(res => {
        this.setHoldTokenInStorage(res);
        return { token: res, new: true };
      }));
    } else {
      return of({ token: session, new: false });
    }
  }

  clearHoldTokenInStorage() {
    localStorage.removeItem(this.holdtoken_storage_key);
  }

  releaseObjects(eventId: string, holdToken: string, seatIds: string[]): Observable<any> {
    const url = `${this.apiUrl}/events/${eventId}/actions/release`;
    return this.http.post<any>(url, { objects: seatIds, holdToken: holdToken }, this.httpOptions);
  }

  private generateHoldToken(): Observable<SeatsioHoldToken> {
    let url = `${this.apiUrl}/hold-tokens`;
    return this.http.post<any>(url, {}, this.httpOptions)
      .pipe(
        map(res => {
          return this.deserialize(res);
        })
      );
  }

  private setHoldTokenInStorage(token: any) {
    localStorage.setItem(this.holdtoken_storage_key, JSON.stringify(token));
  }

  private getHoldTokenFromStorage(): SeatsioHoldToken {
    const data = localStorage.getItem(this.holdtoken_storage_key);
    if (data) {
      return this.deserialize(JSON.parse(data));
    }
    return null;
  }

  private deserialize(data: any): SeatsioHoldToken {
    const token = new SeatsioHoldToken();
    token.holdToken = data.holdToken;
    token.expiresAt = data.expiresAt;
    token.expiresInSeconds = data.expiresInSeconds;
    return token;
  }
}
