import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
// import { MenuItem } from '../header/header.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})

export class FooterComponent implements OnInit {

  public version = environment.version;
  // public exploreItems: MenuItem[] = [
  //   { name: 'About', route:'https://ticketspicket.com/about/'},
  //   { name: 'News', route:'https://ticketspicket.com/news/'},
  //   { name: 'FAQ', route:'https://ticketspicket.com/contact/'},
  // ]

  // public connectItems: MenuItem[] = [
  //   { name: 'Schedule A Demo', route:'https://calendly.com/ticketspicket'},
  //   { name: 'Contact', route:'https://ticketspicket.com/contact/'},
  //   { name: 'Support', route:'https://ticketspicket.com/contact/'},
  // ]

  // public followItems: MenuItem[] = [
  //   { name: 'Facebook', route:'https://www.facebook.com/ticketspicket/'},
  //   { name: 'Instagram', route:'https://www.instagram.com/ticketspicket/'},
  //   { name: 'Twitter', route:'https://twitter.com/ticket_spicket'},
  //   { name: 'LinkedIn', route:'https://www.linkedin.com/company/ticket-spicket/'},
  // ]

  // public legalItems: MenuItem[] = [
  //   { name: 'Privacy Policy', route:'https://ticketspicket.com/privacy-policy/'},
  //   { name: 'Terms and Conditions', route:'https://ticketspicket.com/terms-and-conditions/'},
  // ]

  constructor() { }

  ngOnInit(): void {
  }

}
