export class Location {
  address1: string;
  address2?: string;
  city: string;
  latitude: string;
  longitude: string;
  name: string;
  state: string;
  zip: string;
}
