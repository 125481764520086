import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from "@angular/router";
import { ColorService } from "./color.service";

@Injectable()
export class ColorGuard implements CanActivate {

  constructor(private service: ColorService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this.service.setColor(null);
    return true;
  }
}
