<footer class="footer bg-htblack text-sm pt-2 pb-4 flex flex-row text-center" aria-label="Footer">
    <div class="mx-auto">
        <div class="justify-items-center md:justify-between">
            <a routerLink="/home" class="logo md:inline-block block bg-transparent m-auto mt-6 mb-4 md:mr-8 md:-mb-4 md:mt-1.5 h-11 w-11">
                <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" style="enable-background:new 0 0 44.3 42" viewBox="0 0 44.3 42">
                    <path d="M44.3 32.2v9.5c-.1.2-.3.3-.6.3H24.1c-.4 0-.6-.2-.6-.6v-8.9c0-.4.2-.6.6-.6h3.2c.5 0 .6-.1.6-.6v-4.2c0-.5-.1-.6-.6-.7H16.9c-.5 0-.6.1-.6.6v4.2c0 .5.1.6.6.6h3.2c.5 0 .6.2.6.6v8.9c0 .5-.1.6-.7.6H.5c-.3 0-.5-.1-.6-.3V32c.1-.2.4-.3.6-.3h3.1c.5 0 .6-.1.6-.6V10.7c0-.5-.1-.6-.6-.6H.5c-.3 0-.5-.1-.6-.3V.2C.1.1.3 0 .6 0h19.6c.4 0 .6.2.6.6v9c0 .4-.2.6-.6.6h-3.3c-.4 0-.6.2-.6.6v4.4c0 .5.1.6.6.6h10.5c.4 0 .6-.1.6-.6v-4.4c0-.5-.1-.6-.6-.6h-3.2c-.5 0-.7-.2-.7-.6V.7c0-.5.2-.7.7-.7h19.4c.3 0 .5.1.7.3v9.6c-.1.3-.3.4-.6.3h-3.1c-.5 0-.6.1-.6.6v20.3c0 .5.1.6.6.6h3.2c.2.1.4.2.5.5zm-7-11.2V8.2c0-.5.2-.6.6-.6H41c.5 0 .7-.1.7-.6V3.1c0-.5-.1-.6-.6-.6H26.7c-.5 0-.6.1-.6.6V7c0 .5.1.6.6.6h3.2c.5 0 .6.1.6.6v9.4c0 .5-.2.6-.6.6H14.4c-.5 0-.6-.2-.6-.6V8.2c0-.5.2-.6.6-.6h3.2c.5 0 .6-.2.6-.6V3.1c0-.4-.2-.6-.6-.6H3.1c-.5 0-.6.1-.6.6V7c0 .5.1.6.6.6h3.2c.5.1.7.2.7.7v25.5c0 .5-.2.6-.6.6H3.2c-.4 0-.6.2-.6.6v3.9c0 .4.2.6.6.6h14.5c.5 0 .6-.2.6-.6v-3.8c0-.5-.1-.6-.6-.6h-3.2c-.5 0-.6-.1-.6-.6v-9.3c0-.4.2-.6.6-.6h15.6c.5 0 .6.2.6.6V34c0 .4-.2.6-.6.6h-3.3c-.4 0-.6.2-.6.6v3.9c0 .4.2.6.6.6h14.5c.4 0 .6-.2.6-.6v-3.9c0-.5-.1-.6-.6-.6h-3.1c-.5 0-.7-.1-.7-.7-.2-4.4-.2-8.6-.2-12.9z" style="fill:#fff"/>
                </svg>
            </a>
            <div class="inline-block">
                <a target="_blank" class="border-transparent text-white hover:text-white md:inline-flex block items-center pt-1 text-sm font-light mt-6 md:mr-8 md:mt-0 p-0 border-0 hover:underline" href="https://www.hometownticketing.com/company/#about-us-id">About HomeTown</a>
                <a target="_blank" class="border-transparent text-white hover:text-white md:inline-flex block items-center pt-1 text-sm font-light mt-12 md:mr-8 md:mt-0 p-0 border-0 hover:underline" href="https://www.hometownticketing.com/company/privacy-policy/">Privacy Policy</a>
                <a target="_blank" class="border-transparent text-white hover:text-white md:inline-flex block items-center pt-1 text-sm font-light mt-12 md:mr-8 md:mt-0 p-0 border-0 hover:underline" href="https://www.hometownticketing.com/company/terms-of-service/">Terms of Service</a>
                <a target="_blank" class="border-transparent text-white hover:text-white md:inline-flex block items-center pt-1 text-sm font-light mt-12 md:mr-8 md:mt-0 p-0 border-0 hover:underline" href="https://www.hometownticketing.com/company/contact-us/">Contact Us</a>
                <a target="_blank" class="border-transparent text-white hover:text-white md:inline-flex block items-center pt-1 text-sm font-light mt-12 md:mr-8 md:mt-0 p-0 border-0 hover:underline" href="https://support.hometownticketing.com/FAQsupport/s/">Support</a>
                <span class="text-white md:inline block pt-1 font-medium mt-12 md:mr-8 pb-4 md:pb-0">v{{version}}</span>
            </div>
        </div>
    </div>
</footer>
