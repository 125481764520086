import { Account } from "../account/models/account.model";
import { Location } from "./location.model";

export enum EventStatus {
  scheduled = 0,
  postponed = 1,
  cancelled = -1,
  sold_out = 96,
  on_sale = 97,
  sale_ended = 98,
  completed = 99
}

export class Event {
  id: any;
  title: string;
  subtitle?: string;
  special_event?: string;
  status: number;
  start_date?: string;
  end_date?: string;
  description?: string;
  event_type: string;
  start_sales_date?: string;
  end_sales_date?: string;
  opponent?: string;
  activity: {
    name: string;
    id: any;
    activity_type: string;
    activity_type_id: string;
  };
  final_cover_art_uri: {
    cover_art_uri: string;
  };
  venue: {
    location: Location
  };
  account: Account;
  schedule: Array<{ title: string, start_time: string }>;

  allow_self_checkin: boolean;
  available: number;
  capacity: number;
  has_restricted_seats: boolean;
  has_restricted_reserved_seats: boolean;

  gates_open_date?: string;
  product_id: number;
  has_protected_channels: boolean;
  form_uri: string;
  is_registration_event: boolean;
  is_private: boolean;
}

export class ReservedCategory {
  id: number;
  key: string;
  label: string;
  accessible?: string;
  color?: string;
}

export class TicketType {
  id: any;
  name: string;
  description: string;
  price: number;

  available: number;
  capacity: number;

  reserved_seats: boolean;
  reserved_category: ReservedCategory;

  price_id: number;
  calculatedAvailable: number;
}
