export enum Account_Category_Enum {
  Brand = 'Brand',
  General = 'General',
  Social = 'Social',
  CoverArtUri = 'coverArtURI',
  FacebookUri = 'facebookURI',
  InstagramUri = 'instagramURI',
  Logo2xUri = 'logo2xURI',
  LogoUri = 'logoURI',
  PrimaryActive = 'primaryActive',
  PrimaryColor = 'primaryColor',
  PrimaryHover = 'primaryHover',
  PrimaryText = 'primaryText',
  SecondaryActive = 'secondaryActive',
  SecondaryColor = 'secondaryColor',
  SecondaryHover = 'secondaryHover',
  SecondaryText = 'secondaryText',
  SnapchatUri = 'snapchatURI',
  Timezone = 'timezone',
  TwitterUri = 'twitterURI'
}

export class GeneralSettings {
  public timezone: string;

  constructor() {
    this.timezone = null;
  }
}

export class SocialSettings {
  facebook: string;
  instagram: string;
  twitter: string;
  snapchat: string;

  constructor() {
    this.facebook = null;
    this.instagram = null;
    this.twitter = null;
    this.snapchat = null;
  }
}

export class ColorSettings {
  public base: string;
  public text: string;
  public hover: string;

  constructor() {
    this.base = '';
    this.hover = '';
    this.text = '';
  }
}

export class BrandColors {
  public primary: ColorSettings;
  public secondary: ColorSettings;

  constructor() {
    this.primary = new ColorSettings();
    this.secondary = new ColorSettings();
  }
}

export class ImageSettings {
  logo: string;
  logo2x: string;
  coverArt: string;

  constructor() {
    this.logo = null;
    this.logo2x = null;
    this.coverArt = null;
  }
}

export class BrandSettings {
  images: ImageSettings;
  colors: BrandColors;

  constructor() {
    this.images = new ImageSettings();
    this.colors = new BrandColors();
  }
}

export class AccountSettings {
  general: GeneralSettings;
  social: SocialSettings;
  branding: BrandSettings;

  constructor() {
    this.general = new GeneralSettings();
    this.social = new SocialSettings();
    this.branding = new BrandSettings();
  }

  deserialize(settings: Array<{ category: Account_Category_Enum, value: any }>) {
    this.general = settings.find(x => x.category == Account_Category_Enum.General)?.value;
    this.social = settings.find(x => x.category == Account_Category_Enum.Social)?.value;
    this.branding = settings.find(x => x.category == Account_Category_Enum.Brand)?.value;
    return this;
  }
}
