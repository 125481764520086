import { Injectable } from '@angular/core';
import { Ticket } from '../models';

@Injectable({
  providedIn: 'root',
})
export class TicketService {

  constructor() { }

  getTickets() {
    const tickets: Ticket[] = [];

    return tickets;
  }
}
