import { Location } from "../models";

export abstract class LocationUtil {

  static url(location: Location): string {
    const baseURL = 'https://www.google.com/maps/dir/?api=1&destination=';
    const addressString = baseURL + this.convertToMapFormat(location.address1) + '+'
      + this.convertToMapFormat(location.address2) + '+'
      + this.convertToMapFormat(location.city) + '+'
      + this.convertToMapFormat(location.state) + '+'
      + this.convertToMapFormat(location.zip);

    return addressString;
  }

  private static convertToMapFormat(value: string): string {
    if (value) {
      const res = value.split(' ');
      const formattedString = res.join('+');
      return formattedString;
    } else {
      return '';
    }
  }
}
