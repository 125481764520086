import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { SeatsioHoldToken } from './seats-io-token.service';
import { Cart } from '../models';
import { CartUtil } from '../util';

export class PurchaseStats {
  public purchaseItems: number = 0;
  public subTotal: number = 0;
  public convenienceFee: number = 0;
  public taxes: number = 0;
  public subTotalAfterDiscount: number = 0;

  public get orderTotal() {
    return this.subTotalAfterDiscount + this.convenienceFee + this.taxes;
  }
}

export class PromoCode {
  public code: string;
  public description: string;
}

export interface CreditCard {
  cardType: string,
  last4Digits: number,
  expires: string,
  lastUpdatedOn: string,
  selected?: boolean
}

export interface ICardDetails {
  cardType: string;
  lastTwo: string;
  lastFour: string;
}

export interface PaymentMethod {
  nonce: string;
  description: string;
  details: ICardDetails;
  type: string;
  vaulted: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class CartService {

  purchases = [];
  appliedPromoCodes: PromoCode[] = [];

  public nonce: string;
  public paymentMethod: PaymentMethod;

  constructor(
    private _http: HttpClient
  ) { }

  calculateSubTotal(ticketTypes: any[]) {
    let subTotal = 0;
    ticketTypes.forEach(t => {
      const price = t.price * t.selection;
      subTotal = subTotal + price;
    });
    return subTotal;
  }

  calculateConvenienceFee(subTotal: number) {
    return subTotal * 0.1;
  }

  calculatePurchaseSubTotal() {
    let subTotal = 0;
    this.purchases.forEach(p => {
      subTotal = subTotal + this.calculateSubTotal(p.ticketTypes);
    });
    return subTotal;
  }

  calculateSubTotalAfterDiscount(subTotal: number) {
    const discount = subTotal * 0.25;
    return discount > subTotal ? 0 : subTotal - discount;
  }

  calculatePurchaseConvenienceFee() {
    let convenienceFee = 0;
    this.purchases.forEach(p => {
      convenienceFee = convenienceFee + this.calculateConvenienceFee(this.calculateSubTotal(p.ticketTypes));
    });
    return convenienceFee;
  }

  calculatePurchaseTaxes(subTotal: number) {
    return subTotal * 0.05;
  }

  getPurchaseStats() {
    const purchaseStats = new PurchaseStats();
    purchaseStats.purchaseItems = this.purchases.map(p => p.ticketTypes.map(t => t.selection).reduce((accumulator, currentValue) => accumulator + currentValue, 0))
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    purchaseStats.subTotal = this.calculatePurchaseSubTotal();
    purchaseStats.convenienceFee = this.calculatePurchaseConvenienceFee();
    purchaseStats.subTotalAfterDiscount = this.calculateSubTotalAfterDiscount(purchaseStats.subTotal);
    purchaseStats.taxes = this.calculatePurchaseTaxes(purchaseStats.subTotalAfterDiscount);
    return purchaseStats;
  }

  getCards() {
    const cards: CreditCard[] = [];
    return cards;
  }

  public getClientToken(): Observable<string> {
    const url = `fans/payments/client-token`;
    return this._http.get<string>(url).pipe(
      map((response: any) => this.nonce = response.token)
    )
  };

  public calculateCart(cart: Cart): Observable<Cart> {
    const url = 'fans/guest/calculate-cart';
    return this._http.post<Cart>(url, cart);
  }

  public checkout(cart: Cart): Observable<string> {
    const url = `fans/guest/checkout`;
    const body = CartUtil.serialize(cart);
    return this._http.post<any>(url, body)
      .pipe(map(res => {
        return res.payment.uuid;
      }));
  }
}
