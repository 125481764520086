import { FormBuilder, FormControl, Validators } from "@angular/forms";
import _ from "lodash";
import moment from "moment";
import { Badge } from "../components";
import { Event, EventStatus, TicketType } from "../models";
import { UtilConstants } from "./util.constants";

export abstract class EventUtil {

  static statusBadge(event: Event): Badge {
    const badge = { message: '', type: null } as Badge;
    if (event.status == EventStatus.postponed) {
      badge.type = 'warning';
      badge.message = 'Postponed';
      badge.status = EventStatus.postponed;
      return badge;
    }

    if (event.status == EventStatus.cancelled) {
      badge.type = 'danger';
      badge.message = 'Cancelled';
      badge.status = EventStatus.cancelled;
      return badge;
    }

    if (!event.start_sales_date) {
      badge.message = 'Not Available';
      return badge;
    }

    const currentDate = moment();
    let start_date = event.start_date;
    if (event.schedule && event.schedule.length > 0) {
      const all_start_times = event.schedule.map(s => s.start_time);
      all_start_times.push(event.start_date);
      start_date = _.max(all_start_times);
    }
    const start_sales_date = event.start_sales_date ? moment(event.start_sales_date) : moment(event.start_date).add(-3, 'days');
    const end_date = event.end_date ? event.end_date : moment(start_date).add(3, 'hours');
    const end_sales_date = event.end_sales_date ? event.end_sales_date : moment(start_date).add(3, 'hours');

    if (currentDate.isBefore(start_sales_date)) {
      badge.type = 'warning';
      badge.message = 'Coming Soon';
      return badge;
    }
    if (currentDate.isAfter(end_sales_date) && currentDate.isBefore(end_date)) {
      badge.type = 'danger';
      badge.message = 'Sale Ended';
      badge.status = EventStatus.sale_ended;
      return badge;
    }
    if (currentDate.isAfter(end_date)) {
      badge.type = 'danger';
      badge.message = 'Completed';
      badge.status = EventStatus.completed;
      return badge;
    }

    const isOnSale = currentDate.isSameOrAfter(start_sales_date) && currentDate.isSameOrBefore(end_sales_date);
    if (isOnSale && event.available > 0) {
      badge.type = 'success';
      badge.message = 'On Sale';
      badge.status = EventStatus.on_sale;
      return badge;
    }
    if (isOnSale && event.available == 0) {
      badge.type = 'danger';
      badge.message = 'Sold Out';
      badge.status = EventStatus.sold_out;
      return badge;
    }
    return badge;
  }

  static available(e: Event, t: TicketType): number {
    return t.capacity == 0 ? e.available : Math.min(t.available, e.available);
  }

  static buildTicketTypesForm(fb: FormBuilder, ticketTypes: TicketType[]) {
    const form = fb.group({});
    ticketTypes.forEach(t => {
      form.addControl(UtilConstants.TICKET_TYPE_CONTROL_NAME(t.id), new FormControl(0))
    });
    return form;
  }

  static calculateSubtotal(value: any, ticketTypes: TicketType[]): number {
    let subTotal = 0;
    ticketTypes.forEach(t => {
      const price = t.price * Number(value[UtilConstants.TICKET_TYPE_CONTROL_NAME(t.id)]);
      subTotal = subTotal + price;
    });
    return subTotal;
  }

  static calculateTotalItems(value: any, ticketTypes: TicketType[]): number {
    let total = 0;
    ticketTypes.forEach(t => {
      total = total + Number(value[UtilConstants.TICKET_TYPE_CONTROL_NAME(t.id)]);
    });
    return total;
  }

  static buildDeliveryForm(fb: FormBuilder) {
    const form = fb.group({
      phone: ['', [Validators.required, Validators.pattern(/^\(\d{3}\)\s\d{3}-\d{4}$/)]]
    });
    return form;
  }
}
