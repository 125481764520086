import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import firebase from 'firebase/app';
import { AngularFireDatabase } from '@angular/fire/database';
import { from } from 'rxjs';
@Injectable({ providedIn: 'root' })
export class AuthService {

  constructor(
    private router: Router,
    private auth: AngularFireAuth,
    private db: AngularFireDatabase
  ) {

    auth.onAuthStateChanged(user => {

      if(user) {
       
          db.object(`/metadata/${user.uid}/refreshTime`).valueChanges()
          .subscribe(s=> {            
            user.getIdToken(true);
          });
      }

    });

  }

  async emailSignin({ email, password }: { email: string; password: string; }) {
    return await from(this.auth.signInWithEmailAndPassword(
      email, password)
    );
  }

  async googleSignin() {
    return from(this.auth.signInWithPopup(new firebase.auth.GoogleAuthProvider()));
  }

  async twitterSignin() {
    return from(this.auth.signInWithPopup(new firebase.auth.TwitterAuthProvider()));
  }

  async facebookSignIn() {
    return from(this.auth.signInWithPopup(new firebase.auth.FacebookAuthProvider()));      
  }

  async appleLogin() {
    var provider = new firebase.auth.OAuthProvider('apple.com');
    provider.addScope('email');
    provider.addScope('name');
    return from(this.auth.signInWithPopup(provider));      
  }

  async signOut() {
    this.auth.signOut();
    return this.router.navigate(['/']);
  }

}