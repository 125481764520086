import { ICONS_CREDITCARD_TYPES } from './icon-credit-cards-types';
import { ICONS_ACTIVITIES } from './icon-activities';
import { ICONS_OUTLINE } from './icons-outline';
import { ICONS_SOLID } from './icons-solid';
import { ICONS_CUSTOM } from './icons-custom';

export const ICONS = {
  ...ICONS_OUTLINE,
  ...ICONS_SOLID,
  ...ICONS_ACTIVITIES,
  ...ICONS_CREDITCARD_TYPES,
  ...ICONS_CUSTOM
};

export * from './icon/icon.component';
export * from './icons.module';
