<nav class="bg-white shadow">
  <div class="mx-auto px-2 sm:px-6 lg:px-8">
    <div class="relative flex justify-between h-16">
      <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
        <!-- Mobile menu button -->
        <!-- <button type="button"
          class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
          aria-controls="mobile-menu" aria-expanded="false" (click)="mobileMenuVisible = !mobileMenuVisible">
          <span class="sr-only">Open main menu</span>
          <icon *ngIf="!mobileMenuVisible" name='menu' class="block h-6 w-6"></icon>
          <icon *ngIf="mobileMenuVisible" name='x' class="hidden h-6 w-6"></icon>
        </button> -->
      </div>
      <div class="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
        <div class="grid h-full place-items-center">
          <a *ngFor="let item of menuItems" class="block mr-2 h-8 w-32 flex items-center justify-center sm:items-stretch sm:justify-start" [routerLink]="item.route">
            <img [name]="item.img.name" [src]="item.img.src" [alt]="item.img.alt" [class]="item.img.css">
          </a>
          <!-- <a class="hidden lg:block h-8 w-auto" routerLink="/home">
            <img src="assets/text-logo.png" alt="Ticket Spicket">
          </a> -->
        </div>
      </div>
      <div class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
        <!-- <a *ngIf="auth.user | async as user" [routerLink]="['cart']"
          class="bg-white pr-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 h-6 w-6">
          <span class="sr-only">Cart</span>
          <icon name='shopping-cart' class="h-6 w-6"></icon>
        </a> -->

        <!-- Profile dropdown -->
        <!-- <div #profile class="ml-3 relative" *ngIf="auth.user | async as user">
          <div>
            <button type="button" class="bg-white rounded-full flex text-sm focus:outline-none
            focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" id="user-menu-button" aria-expanded="false"
              aria-haspopup="true">
              <span class="sr-only">Open user menu</span>
              <img class="h-8 w-8 rounded-full" [src]="user.photoURL" alt="">
            </button>
          </div> -->

          <!--
              Dropdown menu, show/hide based on menu state.

              Entering: "transition ease-out duration-200"
                From: "transform opacity-0 scale-95"
                To: "transform opacity-100 scale-100"
              Leaving: "transition ease-in duration-75"
                From: "transform opacity-100 scale-100"
                To: "transform opacity-0 scale-95"
            -->
          <!-- <div *ngIf="profileDropdownVisible" class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1
           bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical"
            aria-labelledby="user-menu-button" tabindex="-1">
            <a href="#" class="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabindex="-1"
              id="user-menu-item-0">Your Profile</a>
            <a href="#" class="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabindex="-1"
              id="user-menu-item-1">Settings</a>
            <button (click)="auth.signOut()" class="block px-4 py-2 text-sm text-gray-700" role="menuitem" tabindex="-1"
              id="user-menu-item-2">
              Sign out
            </button>
          </div> -->
      </div>
    </div>
  </div>
  <!-- Mobile menu, show/hide based on menu state. -->
  <div *ngIf="mobileMenuVisible" class="sm:hidden" id="mobile-menu">
    <div class="pt-2 pb-4 space-y-1">
      <a *ngFor="let item of menuItems" [routerLink]="item.route" (click)="mobileMenuVisible = !mobileMenuVisible"
        class="border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block p-4 border-l-4 text-base font-medium">
        <icon [name]="item.icon.name" class="h-4 w-4 mr-1.5" [class]="item.icon.css" [attr.style]="item.icon.style">
        </icon>
        {{item.name}}
      </a>
      <div *ngIf="auth.user | async as user">
        <a *ngFor="let item of followingAccounts" [routerLink]="item.route"
          (click)="mobileMenuVisible = !mobileMenuVisible" class="w-full items-center border-transparent text-gray-500
          hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block p-4 border-l-4 text-base font-medium">
          <img class="flex-none h-6 w-6 rounded-full" [src]="item.logoURI" [alt]="item.name" />
          <span class="flex-1 font-normal ml-3 truncate">{{item.name}}</span>
        </a>
        <button type="button" (click)="auth.signOut();mobileMenuVisible = !mobileMenuVisible;" class="w-full border-transparent
          text-left text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block p-4
          border-l-4 text-base font-medium" role="menuitem" tabindex="-1" id="user-menu-item-2">
          <icon name="logout" class="h-4 w-4 mr-1.5">
          </icon>
          Sign out
        </button>
      </div>
    </div>
  </div>
</nav>
