export class Ticket {
  id: string;
  type: {
    id: number;
    name: string;
    code: string;
  };
  section?: string;
  row?: string;
  seat?: string;
  selected?: boolean;
  holder?: {
    name: string;
  }
  ticketCode: string;
  redeemed_date?: Date;
  ticket_color?:  string;
}
