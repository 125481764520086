import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router, NavigationEnd } from '@angular/router';

export interface MenuItem {
  name: string;
  route: string;
  icon?: {
    name: string;
    css?: string;
    style?: string;
  };
  img?: {
    name?: string;
    src?: string;
    alt?: string;
    css?: string;
  }
}

export interface AccountItem {
  logoURI?: string;
  name: string;
  route: string;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  menuItems: MenuItem[] = [
    { name: 'Logo', route: '/home', img: { name: 'logo', src: 'https://s3.amazonaws.com/com.ticketspicket.app/logos/Hometown_black.png', alt: 'Hometown Ticketing', css: 'w-10'} },
    // { name: 'Search', route: '/home', icon: { name: 'search' } },
    // { name: 'Tickets', route: '/tickets', icon: { name: 'ticket', css: 'transform rotate-45' } },
    // { name: 'Passes', route: '/passes', icon: { name: 'tag' } },
  ];

  logoURI = 'https://s3.amazonaws.com/com.ticketspicket.agency/e059224d-054c-4006-b89c-bdf60001248a/manatee-hs-logo-240-v10.png';
  route = '/account/e059224d-054c-4006-b89c-bdf60001248a';

  followingAccounts: AccountItem[] = [
    { logoURI: this.logoURI, name: 'Manatee High School', route: this.route },
    { logoURI: this.logoURI, name: 'Manatee High School', route: this.route },
    { logoURI: this.logoURI, name: 'Manatee High School', route: this.route },
  ]

  profileDropdownVisible: boolean = false;
  followingDropdownVisible: boolean = false;
  mobileMenuVisible: boolean = false;

  @ViewChild('following') following;
  @ViewChild('profile') profile;

  constructor(public auth: AngularFireAuth, router: Router) {
    const delay = ms => new Promise(res => setTimeout(res, ms));
    const changeLogo = async (url: string, css: string) => {
      this.menuItems[0].img.css = 'hidden';
      this.menuItems[0].img.src = url;
      await delay(10);
      this.menuItems[0].img.css = css;
    } 
    router.events.subscribe((e: NavigationEnd) => {
      this.route = e.url;
      if(this.route === '/home') {
        changeLogo('https://s3.amazonaws.com/com.ticketspicket.app/logos/Hometown_long_black.svg','w-18');
      } else {
        changeLogo('https://s3.amazonaws.com/com.ticketspicket.app/logos/Hometown_black.png','w-10');
      }
    });
  }

  ngOnInit(): void {
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (this.following && this.following.nativeElement.contains(event.target)) {
      this.followingDropdownVisible = !this.followingDropdownVisible;
    } else {
      this.followingDropdownVisible = false;
    }

    if (this.profile && this.profile.nativeElement.contains(event.target)) {
      this.profileDropdownVisible = !this.profileDropdownVisible;
    } else {
      this.profileDropdownVisible = false;
    }
  }
}
