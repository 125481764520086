import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './layout/layout/layout.component';
import { ColorGuard } from './services/color.guard'

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      {
        path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
        canActivate: [ColorGuard]
      },
      {
        path: 'account', loadChildren: () => import('./account/account.module').then(m => m.AccountModule)
      },
      {
        path: 'account/:accountId/event', loadChildren: () => import('./event/event.module').then(m => m.EventModule)
      },
      {
        path: 'tickets', loadChildren: () => import('./event/event-tickets/event-tickets.module').then(m => m.EventTicketsModule)
      }
    ]
  },
  {
    path: '**', redirectTo: 'home'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
