const stop = `
<svg width="186" height="186" viewBox="0 0 186 186" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M55.0726 5.0935L128.287 4L180.831 54.9975L181.925 128.212L130.927 180.756L57.7125 181.85L5.16863 130.852L4.07513 57.6374L55.0726 5.0935Z" fill="#EF4444" stroke="#EF4444" stroke-width="8"/>
  <path d="M90.6584 136.5C110.679 136.5 126.908 120.27 126.908 100.25V76.0833C126.908 72.0793 123.663 68.8333 119.658 68.8333C115.654 68.8333 112.408 72.0793 112.408 76.0833V61.5833C112.408 57.5793 109.163 54.3333 105.158 54.3333C101.154 54.3333 97.9084 57.5793 97.9084 61.5833V56.75C97.9084 52.7459 94.6625 49.5 90.6584 49.5C86.6544 49.5 83.4084 52.7459 83.4084 56.75V61.5833C83.4084 57.5793 80.1625 54.3333 76.1584 54.3333C72.1544 54.3333 68.9084 57.5793 68.9084 61.5833V90.5833C68.9084 86.5793 65.6625 83.3333 61.6584 83.3333C57.6544 83.3333 54.4084 86.5793 54.4084 90.5833V100.25C54.4084 120.27 70.6381 136.5 90.6584 136.5Z" fill="white"/>
  <path d="M68.9084 90.5833V102.667M68.9084 90.5833V61.5833C68.9084 57.5793 72.1544 54.3333 76.1584 54.3333C80.1625 54.3333 83.4084 57.5793 83.4084 61.5833M68.9084 90.5833C68.9084 86.5793 65.6625 83.3333 61.6584 83.3333C57.6544 83.3333 54.4084 86.5793 54.4084 90.5833V100.25C54.4084 120.27 70.6381 136.5 90.6584 136.5C110.679 136.5 126.908 120.27 126.908 100.25V76.0833C126.908 72.0793 123.663 68.8333 119.658 68.8333C115.654 68.8333 112.408 72.0793 112.408 76.0833M83.4084 61.5833V88.1667M83.4084 61.5833V56.75C83.4084 52.7459 86.6544 49.5 90.6584 49.5C94.6625 49.5 97.9084 52.7459 97.9084 56.75V61.5833M97.9084 61.5833V88.1667M97.9084 61.5833C97.9084 57.5793 101.154 54.3333 105.158 54.3333C109.163 54.3333 112.408 57.5793 112.408 61.5833V76.0833M112.408 76.0833V88.1667" stroke="#EF4444" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`
const checkmark = `
  <svg width="250" height="250" viewBox="0 0 250 250" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="250" height="250" rx="125" fill="#D1FAE5"/>
    <path d="M70 133.125L101.25 164.375L179.375 86.25" stroke="#059669" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
`;

const breadcrumbDivider = `<svg viewBox="0 0 24 44" preserveAspectRatio="none" fill="currentColor" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
<path class="no-stroke" d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
</svg>`;

export const ICONS_CUSTOM = {
  stop,
  checkmark,
  breadcrumbDivider
}
