import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { BrandColors } from '../models';

@Injectable({
  providedIn: 'root',
})
export class ColorService {
  constructor(@Inject(DOCUMENT) private document: Document) { }

  public shadeColor(col: string, amt: number) {
    let usePound = false;

    if (col[0] == '#') {
      col = col.slice(1);
      usePound = true;
    }

    let num = parseInt(col, 16);

    let r = (num >> 16) + amt;

    if (r > 255) r = 255;
    else if (r < 0) r = 0;

    let b = ((num >> 8) & 0x00ff) + amt;

    if (b > 255) b = 255;
    else if (b < 0) b = 0;

    let g = (num & 0x0000ff) + amt;

    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    let color = (g | (b << 8) | (r << 16)).toString(16);

    for (let i = 0; color.length < 6; i++) {
      color = '0' + color;
    }

    return (usePound ? '#' : '') + color;
  }

  public contrastColor(bgColor: string) {
    if (bgColor[0] == '#') {
      bgColor = bgColor.slice(1);
    }

    let num = parseInt(bgColor, 16);

    let r = num >> 16;

    if (r > 255) r = 255;
    else if (r < 0) r = 0;

    let b = (num >> 8) & 0x00ff;

    if (b > 255) b = 255;
    else if (b < 0) b = 0;

    let g = num & 0x0000ff;

    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    let o = Math.round((r * 299 + g * 587 + b * 114) / 1000);
    let fore = o > 150 ? '#000000' : '#ffffff';

    return fore;
  }

  public setColor(settings: BrandColors): void {
    if (!settings) {
      settings = new BrandColors();
      settings.primary.base = '#2014CC';
      settings.secondary.base = '#FF6900';
    }
    let primaryColor = settings.primary.base;
    let secondaryColor = settings.secondary.base;

    let primary50 = this.shadeColor(primaryColor, 90);
    let primary100 = this.shadeColor(primaryColor, 70);
    let primary200 = this.shadeColor(primaryColor, 50);
    let primary300 = this.shadeColor(primaryColor, 33.3);
    let primary400 = this.shadeColor(primaryColor, 16.6);
    let primary500 = this.shadeColor(primaryColor, 0);
    let primary600 = this.shadeColor(primaryColor, -12.5);
    let primary700 = this.shadeColor(primaryColor, -25);
    let primary800 = this.shadeColor(primaryColor, -32.5);
    let primary900 = this.shadeColor(primaryColor, -50);

    let secondary50 = this.shadeColor(secondaryColor, 90);
    let secondary100 = this.shadeColor(secondaryColor, 70);
    let secondary200 = this.shadeColor(secondaryColor, 50);
    let secondary300 = this.shadeColor(secondaryColor, 33.3);
    let secondary400 = this.shadeColor(secondaryColor, 16.6);
    let secondary500 = this.shadeColor(secondaryColor, 0);
    let secondary600 = this.shadeColor(secondaryColor, -12.5);
    let secondary700 = this.shadeColor(secondaryColor, -25);
    let secondary800 = this.shadeColor(secondaryColor, -32.5);
    let secondary900 = this.shadeColor(secondaryColor, -50);

    let styles = [
      { ColorVariable: '--primary-50', value: primary50 },
      { ColorVariable: '--primary-100', value: primary100 },
      { ColorVariable: '--primary-200', value: primary200 },
      { ColorVariable: '--primary-300', value: primary300 },
      { ColorVariable: '--primary-400', value: primary400 },
      { ColorVariable: '--primary-500', value: primary500 },
      { ColorVariable: '--primary-600', value: primary600 },
      { ColorVariable: '--primary-700', value: primary700 },
      { ColorVariable: '--primary-800', value: primary800 },
      { ColorVariable: '--primary-900', value: primary900 },
      { ColorVariable: '--secondary-50', value: secondary50 },
      { ColorVariable: '--secondary-100', value: secondary100 },
      { ColorVariable: '--secondary-200', value: secondary200 },
      { ColorVariable: '--secondary-300', value: secondary300 },
      { ColorVariable: '--secondary-400', value: secondary400 },
      { ColorVariable: '--secondary-500', value: secondary500 },
      { ColorVariable: '--secondary-600', value: secondary600 },
      { ColorVariable: '--secondary-700', value: secondary700 },
      { ColorVariable: '--secondary-800', value: secondary800 },
      { ColorVariable: '--secondary-900', value: secondary900 },
    ];

    styles.forEach(style => {
      this.document.documentElement.style.setProperty(style.ColorVariable, style.value);
    });
  }

  public setDarkMode(): void {

  }
}
