import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import * as Sentry from "@sentry/angular";
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ColorService } from './services/color.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  constructor(
    private _colorService: ColorService,
    router: Router, route: ActivatedRoute
  ) {
    Sentry.configureScope(function (scope) {
      scope.setTag("app-version", environment.version);
    });
    router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe((event: NavigationStart) => {
        if (event.restoredState) {
          router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
            router.navigate([event.url], { relativeTo: route.root })
          );
        }
      });
    router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        document.body.scrollTop = 0;
      });
  }

  ngOnInit() {
    // Set default theme colors
    this._colorService.setColor(null);
  }

}
