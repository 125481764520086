import moment from "moment";
import { Badge } from "../components";
import { Event } from "../models";

export enum TicketStatus {
  ReadyToUse = 1,
  GatesNotOpen = 2,
}

export abstract class TicketUtil {
  static statusBadge(event: Event): Badge {
    const badge = { message: 'Ready To Use', type: 'success', status: TicketStatus.ReadyToUse } as Badge;
    const currentDate = moment();
    const gates_open_date = moment(event.gates_open_date);

    const start_date = moment(event.start_date);
    const end_date = moment(start_date).add(3, 'hours');
    if (moment(currentDate).isBefore(gates_open_date)) {
      badge.message = 'Gates Not Open';
      badge.type = undefined;
      badge.status = TicketStatus.GatesNotOpen
    }
    if (currentDate.isAfter(end_date)) {
      badge.type = 'danger';
      badge.message = 'Completed';
      return badge;
    }
    return badge;
  }
}
